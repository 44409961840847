const unit = +window
  .getComputedStyle(document.documentElement)
  .getPropertyValue('--unit')
  .replace('px', '');

/**
 * Set image width to snap to dot grid
 */
function setImageSize(imageContainer) {
  const gridWidth = imageContainer.querySelector('.dot-grid').offsetWidth;
  const extraWidth = gridWidth % unit;

  if (extraWidth === 0) {
    imageContainer.style.setProperty('--i-width', `${gridWidth - unit}px`);
  } else {
    imageContainer.style.setProperty(
      '--i-width',
      `${gridWidth - unit - extraWidth}px`
    );
  }
}

export default function imageComponent() {
  const images = document.querySelectorAll('.image');

  images.forEach((image) => {
    setImageSize(image);

    window.addEventListener('resize', () => {
      setTimeout(setImageSize(image), 60);
    });
  });
}

document.addEventListener('DOMContentLoaded', function () {
  imageComponent();
});

if (window.acf) {
  window.acf.addAction('render_block_preview', imageComponent);
}
